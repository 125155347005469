import { TermsStyled } from "./styles";
import ScrollTo from "../../utils/scrollTo";

export default function EyecareBiTerms() {
    return (
        <TermsStyled>
            <div className="container">
                <div className="list" data-wow-delay=".6s">
                    <ul className="menu">
                        <li className="list-item">
                            <a onClick={(e) => {ScrollTo("abrangencia", e)}} title="1. Abrangência e Definições" alt="1. Abrangência e Definições"><strong>1. Abrangência e Definições</strong></a>
                        </li>
                        <li className="list-item">
                            <a onClick={(e) => ScrollTo("aceitacao", e)} title="2. Aceitação dos Termos de Uso" alt="2. Aceitação dos Termos de Uso"><strong>2. Aceitação dos Termos de Uso</strong></a>
                        </li>
                        <li className="list-item">
                            <a onClick={(e) => ScrollTo("funcionalidades", e)} title="3. Funcionalidades do Benefício Visão" alt="3. Funcionalidades do Benefício Visão"><strong>3. Funcionalidades do Benefício Visão</strong></a>
                        </li>
                        <li className="list-item">
                            <a onClick={(e) => ScrollTo("servicos", e)} title="4. Acesso aos serviços" alt="4. Acesso aos serviços"><strong>4. Acesso aos serviços</strong></a>
                        </li>
                        <li className="list-item">
                            <a onClick={(e) => ScrollTo("tratamento", e)} title="5. Tratamento e Compartilhamento Dados Pessoais" alt="5. Tratamento e Compartilhamento Dados Pessoais"><strong>5. Tratamento e Compartilhamento Dados Pessoais</strong></a>
                        </li>
                        <li className="list-item">
                            <a onClick={(e) => ScrollTo("compartilhamento", e)} title="6. Compartilhamento de Dados" alt="6. Compartilhamento de Dados"><strong>6. Compartilhamento de Dados</strong></a>
                        </li>
                        <li className="list-item">
                            <a onClick={(e) => ScrollTo("responsabilidade", e)} title="7. Responsabilidades da Empresa Contratante e do RH Representante" alt="7. Responsabilidades da Empresa Contratante e do RH Representante"><strong>7. Responsabilidades da Empresa Contratante e do RH Representante</strong></a>
                        </li>
                        <li className="list-item">
                            <a onClick={(e) => ScrollTo("direitos", e)} title="8. Direitos dos Titulares de Dados" alt="8. Direitos dos Titulares de Dados"><strong>8. Direitos dos Titulares de Dados</strong></a>
                        </li>
                        <li className="list-item">
                            <a onClick={(e) => ScrollTo("seguranca", e)} title="9. Segurança da Informação" alt="9. Segurança da Informação"><strong>9. Segurança da Informação</strong></a>
                        </li>
                        <li className="list-item">
                            <a onClick={(e) => ScrollTo("privacidade", e)} title="10. Privacidade e Proteção de Dados" alt="10. Privacidade e Proteção de Dados"><strong>10. Privacidade e Proteção de Dados</strong></a>
                        </li>
                        <li className="list-item">
                            <a onClick={(e) => ScrollTo("modificacoes", e)} title="11. Modificações destes Termos" alt="11. Modificações destes Termos"><strong>11. Modificações destes Termos</strong></a>
                        </li>
                        <li className="list-item">
                            <a onClick={(e) => ScrollTo("disposicoes", e)} title="12. Disposições Finais" alt="12. Disposições Finais"><strong>12. Disposições Finais</strong></a>
                        </li>
                        <div className="color">
                            <span>Última atualização: 18 de Dezembro de 2024</span>
                        </div>
                    </ul>
                </div>
                <div className="term" data-wow-delay=".7s">
                    <h1>Termos de Uso – Benefício Visão</h1>
                    <p>Prezado usuário,</p>
                    <p>Agradecemos a você pelo acesso à plataforma do Benefício Visão!</p>
                    <p>Antes de começar a navegar pela nossa plataforma, você deve ler e compreender nossos termos de uso e o nosso Aviso de Privacidade, que está disponível em <a href="https://app.beneficiovisao.com.br/login" title="https://app.beneficiovisao.com.br/login" alt="https://app.beneficiovisao.com.br/login">www.app.beneficiovisao.com.br/login</a>,prosseguindo somente se estiver de acordo com estes documentos.</p>

                    <h3 id="abrangencia">1. Abrangência e Definições</h3>
                    <p>1.1. Este Termo de Uso é aplicável ao Benefício Visão, produto digital oferecido pela Eyecare DN Soluções Digitais Ltda., inscrita no CNPJ/ME sob o nº 35.800.459/0001-54, com sede na Rua dos Pinheiros, 623, Cj. 121 e 122, Pinheiros, São Paulo, SP, CEP 05.422-011 ("Eyecare"), para empresas contratantes e seus colaboradores.</p>
                    <p>1.2. O Benefício Visão é um programa digital de saúde visual destinado a promover a prevenção e cuidado efetivo da saúde dos usuários. Ele fornece acesso rápido a documentos gerados em exames e teleconsultas, alinhamento com normas regulamentadoras de saúde ocupacional, e oferece ferramentas de gestão, como relatórios e dashboards, para o time de saúde e RH das empresas contratantes.</p>
                    <p>1.3. Definições:</p>
                    <div className="row">
                        <div className="col-md-11 offset-md-1">
                            <p>- Usuário Final: Colaborador da empresa contratante que se beneficia dos serviços fornecidos pelo Benefício Visão.</p>
                            <p>- Empresa Contratante: Empresa que contrata o Benefício Visão para oferecer aos seus colaboradores.</p>
                            <p>- RH Representante: Setor de Recursos Humanos da Empresa Contratante, que pode agir em nome do Usuário Final.</p>
                        </div>
                    </div>

                    <h3 className="spc" id="aceitacao">2. Aceitação dos Termos de Uso</h3>
                    <p>2.1. O acesso e utilização do Benefício Visão estão condicionados à aceitação destes Termos de Uso.</p>
                    <p>2.2. No caso de colaboradores que não tenham acesso individual ao sistema, como e-mail ou conhecimento suficiente para realizar a aceitação dos Termos de Uso, a aceitação poderá ser realizada pelo RH Representante da Empresa Contratante, que atuará em nome do colaborador.</p>
                    <p>2.3. A Empresa Contratante será responsável por garantir que os colaboradores foram informados de forma adequada sobre os termos aplicáveis e que consentiram ao uso do Benefício Visão conforme estipulado pela legislação vigente, incluindo a LGPD.</p>

                    <h3 id="funcionalidades">3. Funcionalidades do Benefício Visão</h3>
                    <p>3.1. O Benefício Visão oferece diversas funcionalidades voltadas para a promoção da saúde visual dos colaboradores:</p>
                    <div className="row">
                        <div className="col-md-11 offset-md-1">
                            <p>- Triagem Oftalmológica: Ferramenta para avaliação inicial de possíveis problemas oftalmológicos.</p>
                            <p>- Teste de Acuidade Visual e Teste de Ishihara: Testes voltados para identificar problemas de visão e de percepção de cores.</p>
                            <p>- Teleoftalmologia: Realização de consultas oftalmológicas à distância com especialistas.</p>
                            <p>- Gestão Facilitada: Disponibilização de Relatórios e Dashboards para o time de saúde e RH da Empresa Contratante, facilitando o monitoramento e a gestão da saúde visual dos colaboradores.</p>
                            <p>- Descontos em Óticas: Parcerias com óticas para oferecer condições especiais aos usuários finais.</p>
                        </div>
                    </div>
                                        
                    <h3 className="spc" id="servicos">4. Acesso aos serviços</h3>
                    <p>4.1. O uso do sistema do Benefício Visão exige o prévio cadastramento do usuário ou RH Representante, a ser realizado através do preenchimento de formulário eletrônico na própria Plataforma.</p>
                    <p>4.2. O usuário final ou RH Representante deverão efetuar um cadastro para utilização da Plataforma, com o fornecimento de informações pessoais do usuário final, que poderão incluir nome completo, CPF, sexo, e-mail, telefone. Isto feito, será gerado um login e senha de acesso à Plataforma do Benefício Visão. O usuário final ou RH Representante serão responsáveis por qualquer atividade que ocorra através de sua conta e concorda em não ceder a terceiros, a qualquer título, seu nome de usuário e senha ou qualquer direito sobre a conta. Cabe ao usuário final ou RH Representante a responsabilidade por manter sua senha em sigilo e protegida.</p>
                    <p>4.3. Ao aceitar os Termos de Uso, o usuário final ou RH Representante autorizam o recebimento de mensagens publicitárias e quaisquer outros alertas que possam ser enviados diretamente por e-mail ou outro sistema.</p>
                    <p>4.4. A Eyecare poderá cobrar pelo uso das Plataformas ou de determinadas funcionalidades dentro das Plataformas.</p>
                    <p>4.5. Caso o usuário deseje cancelar seu cadastro na Eyecare, basta encaminhar um e-mail para sistemas@eyecarehealth.com.br.</p>

                    <h3 className="spc" id="tratamento">5. Tratamento e Compartilhamento Dados Pessoais</h3>
                    <p>5.1. Dados Coletados: O Benefício Visão coleta dados pessoais dos Usuários Finais, incluindo, mas não se limitando a: nome completo, CPF, telefone, sexo, e-mail,  informações de saúde visual, resultados de exames, histórico médico, e dados de navegação, de acordo com suas preferências de cookies e nos termos constantes de nossa Política de Cookies.</p>
                    <p>5.2. Dados Anonimizados: Para algumas finalidades, como geração de relatórios e análises estatísticas, os dados dos Usuários Finais serão anonimizados, garantindo a conformidade com a LGPD e o respeito aos direitos dos titulares.</p>
                    <p>5.3. Dados Sensíveis: Como o Benefício Visão envolve dados de saúde, serão adotadas medidas adicionais para proteger as informações sensíveis dos Usuários, conforme exigido pela LGPD.</p>

                    <h3 className="spc" id="compartilhamento">6. Compartilhamento de Dados</h3>
                    <p>6.1. Os dados coletados poderão ser compartilhados com:</p>
                    <div className="row">
                        <div className="col-md-11 offset-md-1">
                            <p>- Parceiros Comerciais, como óticas, para oferta de descontos e promoções, conforme informado no item 5 de nosso Aviso de Privacidade.</p>
                            <p>- Empresa Contratante, para fins de gestão de saúde visual e cumprimento de obrigações trabalhistas.</p>
                        </div>
                    </div>
                    <p>6.2. A Empresa Contratante é responsável por assegurar que os dados compartilhados serão utilizados exclusivamente para as finalidades previstas nestes Termos e que os colaboradores estejam cientes do tratamento realizado.</p>
                    <p>6.3. Compartilhamento Interno entre Produtos da Eyecare: Os dados pessoais coletados pelo Benefício Visão poderão ser compartilhados internamente com outros produtos e plataformas da Eyecare, como o Oculli e o Eyecare BI. Esse compartilhamento tem a finalidade de melhorar a experiência do usuário, oferecer novos serviços e promover os produtos da Eyecare, inclusive para fins de marketing e ativações.</p>
                    
                    <h3 className="spc" id="responsabilidade">7. Responsabilidades da Empresa Contratante e do RH Representante</h3>
                    <p>7.1. A Empresa Contratante e seu RH Representante são responsáveis por garantir que os colaboradores tenham sido devidamente informados sobre os Termos de Uso e que o compartilhamento de dados pessoais está amparado por uma base legal válida, legítima e adequada  de acordo com a LGPD.</p>
                    <p>7.2. A Eyecare não será responsável por falhas na comunicação entre o RH Representante e os colaboradores, devendo a Empresa Contratante arcar com qualquer consequência decorrente da falta de transparência na aceitação destes Termos.</p>
                    
                    <h3 className="spc" id="direitos">8. Direitos dos Titulares de Dados</h3>
                    <p>8.1. Caso o usuário não tenha acesso direto à plataforma as solicitações devem ser feitas à Empresa Contratante, que será responsável por comunicar à Eyecare para que as providências sejam tomadas..</p>
                    
                    <h3 className="spc" id="seguranca">9. Segurança da Informação</h3>
                    <p>9.1. A Eyecare adota medidas técnicas e administrativas adequadas para proteger os dados pessoais dos Usuários Finais contra acessos não autorizados, perda, alteração, ou qualquer forma de tratamento inadequado.</p>
                    <p>9.2. A Empresa Contratante deve garantir a segurança dos dados no ambiente de sua responsabilidade, incluindo o controle das informações por meio do RH Representante.</p>

                    <h3 className="spc" id="privacidade">10. Privacidade e Proteção de Dados</h3>
                    <p>10.1. A Eyecare coleta e utiliza dados pessoais do Usuário durante sua navegação pelo website. Consulte as práticas da Eyecare com relação ao uso de seus dados pessoais no Aviso de Privacidade da Eyecare <a href="https://eyecarehealth.com.br/termos-e-politicas/aviso-de-privacidade" title="https://eyecarehealth.com.br/termos-e-politicas/aviso-de-privacidade" alt="https://eyecarehealth.com.br/termos-e-politicas/aviso-de-privacidade">www.eyecarehealth.com.br/termos-e-politicas/aviso-de-privacidade</a></p>
                    <p>10.2. Os dados pessoais dos Usuários cadastrados na plataforma do Benefício Visão, serão mantidos por tempo indeterminado como forma de viabilizar o acesso do usuário ao seu histórico de utilização. Caso o usuário opte por cancelar seu cadastro, a Eyecare procederá à exclusão, ou anonimização, dos dados pessoais do usuário, com exceção daqueles aos quais deverá manter para cumprimento de obrigações legais ou regulatórias, bem como para resguardar seus direitos.</p>

                    <h3 className="spc" id="modificacoes">11. Modificações destes Termos</h3>
                    <p>11.1. Estes Termos de Uso são regidos pelas leis da República Federativa do Brasil, sendo eleito o foro da Comarca da Capital do estado de São Paulo para dirimir qualquer controvérsia.</p>

                    <h3 className="spc" id="disposicoes">12. Disposições Finais</h3>
                    <p>12.1. Estes Termos de Uso e todas as relações decorrentes dele são regidos pelas leis da República Federativa do Brasil, sendo eleito o foro da Comarca da Capital do estado de São Paulo para dirimir qualquer controvérsia.</p>
                </div>
            </div>
        </TermsStyled>
    );
}