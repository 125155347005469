import { TermsStyled } from "./styles";
import ScrollTo from "../../utils/scrollTo";

export default function EyecareBiTerms() {
    return (
        <TermsStyled>
            <div className="container">
                <div className="list" data-wow-delay=".6s">
                    <ul className="menu">
                        <li className="list-item">
                            <a onClick={(e) => {ScrollTo("abrangencia", e)}} title="1. Abrangência e Definições" alt="1. Abrangência e Definições"><strong>1. Abrangência e Definições</strong></a>
                        </li>
                        <li className="list-item">
                            <a onClick={(e) => ScrollTo("aceitacao", e)} title="2. Aceitação dos Termos de Uso" alt="2. Aceitação dos Termos de Uso"><strong>2. Aceitação dos Termos de Uso</strong></a>
                        </li>
                        <li className="list-item">
                            <a onClick={(e) => ScrollTo("funcionalidades", e)} title="3. Funcionalidades do Eyecare BI" alt="3. Funcionalidades do Eyecare BI"><strong>3. Funcionalidades do Eyecare BI</strong></a>
                        </li>
                        <li className="list-item">
                            <a onClick={(e) => ScrollTo("servicos", e)} title="4. Acesso aos serviços" alt="4. Acesso aos serviços"><strong>4. Acesso aos serviços</strong></a>
                        </li>
                        <li className="list-item">
                            <a onClick={(e) => ScrollTo("tratamento", e)} title="5. Tratamento de Dados Pessoais" alt="5. Tratamento de Dados Pessoais"><strong>5. Tratamento de Dados Pessoais</strong></a>
                        </li>
                        <li className="list-item">
                            <a onClick={(e) => ScrollTo("compartilhamento", e)} title="6. Compartilhamento de Dados" alt="6. Compartilhamento de Dados"><strong>6. Compartilhamento de Dados</strong></a>
                        </li>
                        <li className="list-item">
                            <a onClick={(e) => ScrollTo("responsabilidade", e)} title="7. Responsabilidade e Compliance Médico" alt="7. Responsabilidade e Compliance Médico"><strong>7. Responsabilidade e Compliance Médico</strong></a>
                        </li>
                        <li className="list-item">
                            <a onClick={(e) => ScrollTo("seguranca", e)} title="8. Segurança da Informação" alt="8. Segurança da Informação"><strong>8. Segurança da Informação</strong></a>
                        </li>
                        <li className="list-item">
                            <a onClick={(e) => ScrollTo("privacidade", e)} title="9. Privacidade e Proteção de Dados" alt="9. Privacidade e Proteção de Dados"><strong>9. Privacidade e Proteção de Dados</strong></a>
                        </li>
                        <li className="list-item">
                            <a onClick={(e) => ScrollTo("modificacoes", e)} title="10. Modificações dos Termos" alt="10. Modificações dos Termos"><strong>10. Modificações dos Termos</strong></a>
                        </li>
                        <li className="list-item">
                            <a onClick={(e) => ScrollTo("disposicoes", e)} title="11. Disposições Finais" alt="11. Disposições Finais"><strong>11. Disposições Finais</strong></a>
                        </li>
                        <div className="color">
                            <span>Última atualização: 16 de Dezembro de 2024</span>
                        </div>
                    </ul>
                </div>
                <div className="term" data-wow-delay=".7s">
                    <h1>Termos de Uso – Eyecare BI</h1>
                    <p>Prezado usuário,</p>
                    <p>Agradecemos a você pelo acesso à plataformas da Eyecare BI!</p>
                    <p>Antes de começar a navegar pela nossa plataforma, você deve ler e compreender nossos termos de uso e o nosso Aviso de Privacidade, que está disponível em <a href="https://eyecarebi.eyecarehealth.com.br" title="https://eyecarebi.eyecarehealth.com.br" alt="https://eyecarebi.eyecarehealth.com.br">www.eyecarebi.eyecarehealth.com.br</a>, prosseguindo somente se estiver de acordo com este documento.</p>

                    <h3 id="abrangencia">1. Abrangência e Definições</h3>
                    <p>1.1. Este Termo de Uso é aplicável ao Eyecare BI, produto digital oferecido pela Eyecare DN Soluções Digitais Ltda., inscrita no CNPJ/ME sob o nº 35.800.459/0001-54, com sede na Rua dos Pinheiros, 623, Cj. 121 e 122, Pinheiros, São Paulo, SP, CEP 05.422-011 ("Eyecare"), para profissionais médicos, clínicas e parceiros comerciais.</p>
                    <p>1.2. O Eyecare BI é uma plataforma digital voltada para profissionais e clínicas oftalmológicas, fornecendo ferramentas para otimização da gestão clínica, análise de dados e suporte à tomada de decisão.</p>
                    <p>1.3. Definições:</p>
                    <div className="row">
                        <div className="col-md-11 offset-md-1">
                            <p>- Usuário Médico: Médico que utiliza o Eyecare BI para gerenciar informações de pacientes e otimizar processos da clínica.</p>
                            <p>- Parceiros Comerciais: Empresas ou entidades que colaboram com a Eyecare para proporcionar serviços e benefícios adicionais aos usuários do Eyecare BI.</p>
                        </div>
                    </div>

                    <h3 className="spc" id="aceitacao">2. Aceitação dos Termos de Uso</h3>
                    <p>2.1. O acesso e a utilização do Eyecare BI estão condicionados à aceitação destes Termos de Uso.</p>
                    <p>2.2. Ao utilizar o Eyecare BI, o Usuário Médico ou Parceiro Comercial concorda com todos os termos aqui descritos, incluindo o tratamento e compartilhamento dos dados, conforme descrito neste documento.</p>
                    
                    <h3 id="funcionalidades">3. Funcionalidades do Eyecare BI</h3>
                    <p>3.1. O Eyecare BI oferece funcionalidades voltadas para a gestão oftalmológica:</p>
                    <div className="row">
                        <div className="col-md-11 offset-md-1">
                            <p>- Gestão de Pacientes: Controle dos prontuários e informações dos pacientes.</p>
                            <p>- Dashboards de Análise: Ferramentas para análise de desempenho e relatórios de produtividade da clínica.</p>
                            <p>- Relatórios Personalizados: Relatórios para suporte à tomada de decisões clínicas e administrativas.</p>
                        </div>
                    </div>
                                        
                    <h3 className="spc" id="servicos">4. Acesso aos serviços</h3>
                    <p>4.1. O uso do sistema do Eyecare BI exige o prévio cadastramento do Usuário Médico ou Parceiro Comercial, a ser realizado através do preenchimento de formulário eletrônico na própria Plataforma.</p>
                    <p>4.2. O Usuário Médico ou Parceiro Comercial deverão efetuar um cadastro para utilização da Plataforma, com o fornecimento de informações pessoais, que poderão incluir Nome completo, CPF, telefone, endereço, e-mail, CRM. Isto feito, será gerado um login e senha de acesso à Plataforma do Eyecare BI. O Usuário Médico ou Parceiro Comercial serão responsáveis por qualquer atividade que ocorra através de sua conta e concorda em não ceder a terceiros, a qualquer título, seu nome de usuário e senha ou qualquer direito sobre a conta. Cabe ao Usuário Médico ou Parceiro Comercial a responsabilidade por manter sua senha em sigilo e protegida.</p>
                    <p>4.3. Ao aceitar os Termos de Uso, o Usuário Médico ou Parceiro Comercial autorizam o recebimento de mensagens publicitárias e quaisquer outros alertas que possam ser enviados diretamente por e-mail ou outro sistema.</p>
                    <p>4.4. A Eyecare poderá cobrar pelo uso das Plataformas ou de determinadas funcionalidades dentro das Plataformas.</p>
                    <p>4.5. Caso o usuário deseje cancelar seu cadastro na Eyecare, basta encaminhar um e-mail para sistemas@eyecarehealth.com.br.</p>

                    <h3 className="spc" id="tratamento">5. Tratamento de Dados Pessoais</h3>
                    <p>5.1. Dados Coletados: O Eyecare BI coleta dados pessoais dos Usuários Médicos e dos pacientes sob seus cuidados, incluindo nome completo, CPF, telefone, sexo, e-mail, endereço e CRM informações de saúde oftalmológica, resultados de exames e informações administrativas da clínica.</p>
                    <p>5.2. Dados Sensíveis: Como o Eyecare BI envolve dados de saúde, serão adotadas medidas adicionais para proteger as informações sensíveis dos Usuários, conforme exigido pela LGPD.</p>

                    <h3 className="spc" id="compartilhamento">6. Compartilhamento de Dados</h3>
                    <p>6.1. Compartilhamento entre Produtos Eyecare: Os dados coletados pelo Eyecare BI, incluindo informações dos pacientes cadastrados pelos Usuários Médicos, poderão ser compartilhados internamente com outros produtos da Eyecare, como o Benefício Visão e o Oculli, para otimização de serviços, campanhas de marketing e ativações.</p>
                    <p>6.2. Autorização para Compartilhamento de Dados dos Pacientes: Ao aceitar este Termo, o Usuário Médico autoriza expressamente que os dados dos pacientes sejam compartilhados com outros produtos da Eyecare e com Parceiros Comerciais para campanhas de marketing e ativações.</p>
                    <p>6.3. Compartilhamento com Parceiros Comerciais: Dados poderão ser compartilhados com Parceiros Comerciais, desde que haja consentimento expresso dos Usuários Médicos e dos pacientes, conforme informado no item 5 de nosso</p>
                    
                    <h3 className="spc" id="responsabilidade">7. Responsabilidade e Compliance Médico</h3>
                    <p>7.1. Responsabilidade do Usuário Médico: O Usuário Médico é o único responsável pelo conteúdo e pelas informações inseridas na plataforma Eyecare BI, incluindo dados dos pacientes, diagnósticos, tratamentos e condutas médicas. A Eyecare não tem qualquer responsabilidade sobre a veracidade ou precisão das informações fornecidas.</p>
                    <p>7.2. Conformidade com Normas Médicas: Os Usuários Médicos devem cumprir integralmente as normas do Código de Ética Médica, Resoluções do Conselho Federal de Medicina (CFM), e demais legislações aplicáveis ao exercício da medicina. O descumprimento dessas normas é de responsabilidade exclusiva do Usuário Médico.</p>
                    <p>7.3. Responsabilidade da Eyecare: A Eyecare não se responsabiliza por qualquer consequência decorrente de informações incorretas, desatualizadas ou incompletas inseridas pelos Usuários Médicos, nem por erros de diagnóstico ou tratamento que resultem do uso do Eyecare BI. A responsabilidade pela prestação de atendimento de saúde é exclusiva do Usuário Médico.</p>
                    <p>7.4. Isenção de Responsabilidade por Serviços Médicos: A Eyecare BI é uma plataforma de suporte e gestão e não presta serviços médicos. Todas as decisões relativas a tratamentos e diagnósticos são de responsabilidade exclusiva dos Usuários Médicos. A Eyecare BI não garante a precisão das informações inseridas, cabendo ao médico avaliar os dados antes de tomar qualquer decisão clínica.</p>
                    <p>7.5. Auditorias e Conformidade: A Eyecare se reserva o direito de auditar o uso da plataforma para garantir a conformidade com as normas de compliance e legislações aplicáveis. Em caso de não conformidade, a Eyecare poderá suspender o acesso do Usuário Médico, sem prejuízo de outras medidas legais aplicáveis.</p>
                    
                    <h3 className="spc" id="seguranca">8. Segurança da Informação</h3>
                    <p>8.1. A Eyecare adota medidas técnicas e administrativas para proteger os dados pessoais contra acessos não autorizados e outros riscos.</p>
                    <p>8.2. Informações são protegidas por tecnologias de criptografia e controle de acesso restrito.</p>
                    
                    <h3 className="spc" id="privacidade">9. Privacidade e Proteção de Dados</h3>
                    <p>9.1. A Eyecare coleta e utiliza dados pessoais do Usuário Médico durante sua navegação pelo Eyecare BI. Consulte as práticas da Eyecare com relação ao uso de seus dados pessoais no Aviso de Privacidade da Eyecare  <a href="https://eyecarehealth.com.br/termos-e-politicas/aviso-de-privacidade" title="https://eyecarehealth.com.br/termos-e-politicas/aviso-de-privacidade" alt="https://eyecarehealth.com.br/termos-e-politicas/aviso-de-privacidade">www.eyecarehealth.com.br/termos-e-politicas/aviso-de-privacidade</a></p>
                    
                    <h3 className="spc" id="modificacoes">10. Modificações dos Termos</h3>
                    <p>10.1. A Eyecare se reserva o direito de modificar estes Termos de Uso a qualquer momento. O Usuário Médico será notificado e deverá aceitar os novos termos para continuar utilizando o Eyecare BI.</p>
                    
                    <h3 className="spc" id="disposicoes">11. Disposições Finais</h3>
                    <p>11.1. Estes Termos de Uso são regidos pelas leis da República Federativa do Brasil, sendo eleito o foro da Comarca da Capital do estado de São Paulo para dirimir qualquer controvérsia.</p>
                </div>
            </div>
        </TermsStyled>
    );
}