import { TermsStyled } from "./styles";
import ScrollTo from "../../utils/scrollTo";

export default function EyecareBiTerms() {
    return (
        <TermsStyled>
            <div className="container">
                <div className="list" data-wow-delay=".6s">
                    <ul className="menu">
                        <li className="list-item">
                            <a onClick={(e) => {ScrollTo("abrangencia", e)}} title="1. Abrangência e Definições" alt="1. Abrangência e Definições"><strong>1. Abrangência e Definições</strong></a>
                        </li>
                        <li className="list-item">
                            <a onClick={(e) => ScrollTo("aceitacao", e)} title="2. Aceitação dos Termos de Uso" alt="2. Aceitação dos Termos de Uso"><strong>2. Aceitação dos Termos de Uso</strong></a>
                        </li>
                        <li className="list-item">
                            <a onClick={(e) => ScrollTo("funcionalidades", e)} title="3. Funcionalidades do Oculli" alt="3. Funcionalidades do Oculli"><strong>3. Funcionalidades do Oculli</strong></a>
                        </li>
                        <li className="list-item">
                            <a onClick={(e) => ScrollTo("servicos", e)} title="4. Acesso aos serviços" alt="4. Acesso aos serviços"><strong>4. Acesso aos serviços</strong></a>
                        </li>
                        <li className="list-item">
                            <a onClick={(e) => ScrollTo("tratamento", e)} title="5. Tratamento de Dados Pessoais" alt="5. Tratamento de Dados Pessoais"><strong>5. Tratamento de Dados Pessoais</strong></a>
                        </li>
                        <li className="list-item">
                            <a onClick={(e) => ScrollTo("compartilhamento", e)} title="6. Compartilhamento de Dados" alt="6. Compartilhamento de Dados"><strong>6. Compartilhamento de Dados</strong></a>
                        </li>
                        <li className="list-item">
                            <a onClick={(e) => ScrollTo("seguranca", e)} title="7. Segurança da Informação" alt="7. Segurança da Informação"><strong>7. Segurança da Informação</strong></a>
                        </li>
                        <li className="list-item">
                            <a onClick={(e) => ScrollTo("privacidade", e)} title="8. Privacidade e Proteção de Dados" alt="8. Privacidade e Proteção de Dados"><strong>8. Privacidade e Proteção de Dados</strong></a>
                        </li>
                        <li className="list-item">
                            <a onClick={(e) => ScrollTo("modificacoes", e)} title="9. Modificações destes Termos" alt="9. Modificações destes Termos"><strong>9. Modificações destes Termos</strong></a>
                        </li>
                        <li className="list-item">
                            <a onClick={(e) => ScrollTo("disposicoes", e)} title="10. Disposições Finais" alt="10. Disposições Finais"><strong>10. Disposições Finais</strong></a>
                        </li>
                        <div className="color">
                            <span>Última atualização: 18 de Dezembro de 2024</span>
                        </div>
                    </ul>
                </div>
                <div className="term" data-wow-delay=".7s">
                    <h1>Termos de Uso – Oculli</h1>
                    <p>Prezado usuário,</p>
                    <p>Agradecemos a você pelo acesso ao aplicativo do Oculli!</p>
                    <p>Antes de começar a navegar pelo nosso aplicativo, você deve ler e compreender nossos termos de uso e o nosso Aviso de Privacidade, que está disponível diretamente no aplicativo, prosseguindo somente se estiver de acordo com estes documentos.</p>

                    <h3 id="abrangencia">1. Abrangência e Definições</h3>
                    <p>1.1. Este Termo de Uso é aplicável ao Benefício Visão, produto digital oferecido pela Eyecare DN Soluções Digitais Ltda., inscrita no CNPJ/ME sob o nº 35.800.459/0001-54, com sede na Rua dos Pinheiros, 623, Cj. 121 e 122, Pinheiros, São Paulo, SP, CEP 05.422-011 ("Eyecare"), para empresas contratantes e seus colaboradores.</p>
                    <p>1.2. O Oculli é uma plataforma digital voltada para médicos e pacientes, fornecendo acesso a informações de saúde oftalmológica, como exames, laudos, prescrições e agendamentos. O produto tem como finalidade facilitar o acompanhamento da saúde visual e melhorar a comunicação entre médicos e pacientes.</p>
                    <p>1.3. Definições:</p>
                    <div className="row">
                        <div className="col-md-11 offset-md-1">
                            <p>- Usuário Paciente: Paciente que utiliza o Oculli para acessar seus dados de saúde oftalmológica.</p>
                            <p>- Usuário Médico: Médico que utiliza o Oculli para prestar serviços de acompanhamento médico</p>
                        </div>
                    </div>

                    <h3 className="spc" id="aceitacao">2. Aceitação dos Termos de Uso</h3>
                    <p>2.1. O acesso e a utilização do Oculli estão condicionados à aceitação destes Termos de Uso.</p>
                    <p>2.2. Ao utilizar o Oculli, o Usuário (Paciente ou Médico) concorda com todos os termos aqui descritos, incluindo o tratamento e o compartilhamento de seus dados, conforme descrito neste documento.</p>

                    <h3 id="funcionalidades">3. Funcionalidades do Oculli</h3>
                    <p>3.1. O Oculli oferece diversas funcionalidades voltadas para a promoção da saúde visual:</p>
                    <div className="row">
                        <div className="col-md-11 offset-md-1">
                            <p>- Acesso a Exames e Laudos: Usuários Pacientes podem acessar seus exames e laudos médicos.</p>
                            <p>- Agendamentos e Prescrições: Usuários Pacientes podem visualizar agendamentos e prescrições médicas relacionadas ao tratamento oftalmológico.</p>
                            <p>- Suporte para Médicos: Usuários Médicos têm acesso a informações dos pacientes e podem gerenciar agendamentos e registros.</p>
                        </div>
                    </div>
                                        
                    <h3 className="spc" id="servicos">4. Acesso aos serviços</h3>
                    <p>4.1. A fim de acessar e usar os sistemas e serviços da Oculli, o usuário deverá manter e operar o software e hardware necessários e atualizados em seus dispositivos. O usuário é responsável por adquirir, instalar e manter todo o software e hardware necessários para acessar aplicativo e usar os serviços, sem imputar qualquer dificuldade técnica que venha a ser enfrentada decorrente de tal uso à Oculli.</p>
                    <p>4.2. O uso do sistema do Oculli exige o prévio cadastramento do usuário, a ser realizado através do preenchimento de formulário eletrônico na própria Plataforma.</p>
                    <p>4.3. O usuário deverá efetuar um cadastro para utilização da Plataforma, com o fornecimento de informações pessoais do usuário final, que poderão incluir nome completo, CPF, sexo, e-mail, telefone. Isto feito, será gerado um login e senha de acesso à Plataforma do Oculli. O usuário será responsável por qualquer atividade que ocorra através de sua conta e concorda em não ceder a terceiros, a qualquer título, seu nome de usuário e senha ou qualquer direito sobre a conta. Cabe ao usuário a responsabilidade por manter sua senha em sigilo e protegida.</p>
                    <p>4.4. Ao iniciar o uso da Plataforma da Oculli, o usuário autoriza a ativação do sistema de geolocalização de seu aparelho, permitindo o acesso ao local em que se encontra. O sistema de geolocalização poderá ser desativado pelo usuário a qualquer momento, o que implicará automaticamente na restrição de acesso a algumas ferramentas da Plataforma. </p>
                    <p>4.5. A Oculli não se responsabiliza por qualquer falha no recebimento de alertas e mensagens decorrentes de sua impossibilidade de acesso à internet, por qualquer motivo, como esgotamento do pacote de dados, área sem sinal de recepção de dados, ou qualquer outro que decorra da falta de meios de acesso do usuário.4.6. Ao aceitar os Termos de Uso, o usuário autoriza o recebimento de mensagens publicitárias e quaisquer outros alertas que possam ser enviados diretamente por e-mail ou outro sistema.</p>
                    <p>4.6. A Eyecare poderá cobrar pelo uso das Plataformas ou de determinadas funcionalidades dentro das Plataformas.</p>
                    <p>4.7. Caso o usuário deseje cancelar seu cadastro na Eyecare, basta encaminhar um e-mail para sistemas@eyecarehealth.com.br.</p>

                    <h3 className="spc" id="tratamento">5. Tratamento de Dados Pessoais</h3>
                    <p>5.1. Dados Coletados: O Oculli coleta dados pessoais dos Usuários, incluindo, mas não se limitando a: nome completo, CPF, telefone, sexo, e-mail, informações de saúde oftalmológica, resultados de exames, prescrições, e dados de navegação na plataforma.</p>
                    <p>5.2. Dados Sensíveis: Como o Oculli envolve dados de saúde, serão adotadas medidas adicionais para proteger as informações sensíveis dos Usuários, conforme exigido pela LGPD.</p>

                    <h3 className="spc" id="compartilhamento">6. Compartilhamento de Dados</h3>
                    <p>6.1. Compartilhamento entre Produtos Eyecare: Os dados coletados pelo Oculli poderão ser compartilhados internamente com outros produtos e plataformas da Eyecare, como o Benefício Visão e o Eyecare BI. Esse compartilhamento tem a finalidade de melhorar a experiência do Usuário, promover novos serviços e realizar campanhas de marketing e ativações direcionadas.</p>
                    <p>6.2. Compartilhamento com Terceiros: Os dados poderão ser compartilhados com Parceiros Comerciais, como clínicas e indústrias, desde que anonimizados, ou mediante  consentimento expresso, livre e destacado do Usuário para tal finalidade.</p>
                    
                    <h3 className="spc" id="seguranca">7. Segurança da Informação</h3>
                    <p>7.1. A Eyecare adota medidas técnicas e administrativas adequadas para proteger os dados pessoais dos Usuários contra acessos não autorizados, perda, alteração, ou qualquer forma de tratamento inadequado.</p>
                    
                    <h3 className="spc" id="privacidade">8. Privacidade e Proteção de Dados</h3>
                    <p>8.1. A Eyecare coleta e utiliza dados pessoais do Usuário durante sua navegação pelo aplicativo. Consulte as práticas da Eyecare com relação ao uso de seus dados pessoais no Aviso de Privacidade da Eyecare <a href="https://eyecarehealth.com.br/termos-e-politicas/aviso-de-privacidade" title="https://eyecarehealth.com.br/termos-e-politicas/aviso-de-privacidade" alt="https://eyecarehealth.com.br/termos-e-politicas/aviso-de-privacidade">www.eyecarehealth.com.br/termos-e-politicas/aviso-de-privacidade</a></p>
                    <p>8.2. Os dados pessoais dos Usuários cadastrados na plataforma da Eyecare, serão mantidos por tempo indeterminado como forma de viabilizar o acesso do cliente ao seu histórico de utilização. Caso o cliente opte por cancelar seu cadastro, a Eyecare procederá à exclusão, ou anonimização, dos dados pessoais do cliente, com exceção daqueles aos quais deverá manter para cumprimento de obrigações legais ou regulatórias, bem como para resguardar seus direitos.</p>

                    <h3 className="spc" id="modificacoes">9. Modificações destes Termos</h3>
                    <p>9.1. A Eyecare se reserva o direito de modificar estes Termos de Uso a qualquer momento. O Usuário será notificado através dos canais de comunicação fornecidos durante o cadastro, e deverá aceitar os novos termos para continuar utilizando o Oculli.</p>

                    <h3 className="spc" id="disposicoes">10. Disposições Finais</h3>
                    <p>10.1. Estes Termos de Uso e todas as relações decorrentes dele são regidos pelas leis da República Federativa do Brasil, sendo eleito o foro da Comarca da Capital do estado de São Paulo para dirimir qualquer controvérsia.</p>


                </div>
            </div>
        </TermsStyled>
    );
}